<template>
  <div>
    <v-dialog v-model="openModal" max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="text-center font-weight-bold">DETALLE DE PEDIDO</p>
            </v-col>

            <v-col cols="12" v-show="detalles.length > 0">
              <v-simple-table class="tabla-detalles">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>CODIGO DE MONTURA</th>
                    <th>NOMBRE DE MONTURA</th>
                    <th>CANTIDAD</th>
                    <th>TIPO DE PEDIDO</th>
                    <th>LUNAS</th>
                    <th>MODO DE ENVIO DE RECETA</th>
                    <th>RECETA SUBIDA</th>
                    <th>RECETA FORMULARIO</th>
                    <!-- <th>COLOR E INTENSIDAD</th>   -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, index) in detalles" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td>
                      <p v-text="d.producto.codigo"></p>
                    </td>
                    <td>
                      <p v-text="d.producto.nombre"></p>
                    </td>
                    <td>
                      <p v-text="d.cantidad"></p>
                    </td>
                    <td>
                      <p v-if="d.tipoProducto.nombre == 'Montura'" >
                        {{  (d.modelo.idmodelo_producto == 3 ? 'De sol' : d.modelo.nombre) + ' -> ' + d.tipoMedida.nombre }}
                      </p>
                      <p v-if="d.tipoProducto.nombre == 'Lentes de contacto'" >
                        X{{ d.lc_cajas_oi }} caja(s) OI + X{{ d.lc_cajas_od }} caja(s) OD 
                        <template v-if="d.lc_color_idlc_color">
                          > Color {{ d.lc_color_nombre }}
                        </template>
                      </p>
                    </td>
                    <td>
                      <!-- <p v-text="d.luna.nombre"></p> -->
                      <p v-text="d.tratamientos_alias+(d.idtratamiento_color ? ' > '+d.tratamientoColor.nombre : '' )"></p>
                    </td>
                    <td>
                      <p v-text="d.metodoEnvioMedida.nombre"></p>
                    </td>
                    <td>
                      <v-btn
                        v-if="d.medida_ruta != null && d.medida_ruta != ''"
                        :href="assetAWS('ventas/recetas_subidas/' + d.medida_ruta)"
                        target="_blank" color="info" text
                        > 
                        VER
                      </v-btn>

                      <v-btn
                        v-if="d.lc_medida_ruta != null && d.lc_medida_ruta != ''"
                        :href="assetAWS('ventas/recetas_subidas/' + d.lc_medida_ruta)"
                        target="_blank" color="info" text
                        > 
                        VER
                      </v-btn>
                    </td>
                    <td>
                      <v-btn v-if="d.tipoProducto.nombre == 'Lentes de contacto'" @click=" datosLcMedidaFormulario(d) " color="info" text > VER MEDIDA </v-btn>
                      <v-btn v-else @click=" datosMedidaFormulario(d) " color="info" text > VER MEDIDA </v-btn>
                    </td>
                    <!-- <td>
                      <p v-if="d.idtratamiento_color" class="text-center">
                        <img :src="assetAWS(d.tratamientoColor.imagen)" style="width: 35px; margin: 10px">
                      </p>
                    </td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

       
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>

            <medida-formulario
              v-if="mostrarMedidaFormulario"
              :processForm="processForm"
              v-model="medidaForm"
              @cerrar="mostrarMedidaFormulario = false;"
              @modificar="modificarMedida"
              >
            </medida-formulario>

            <lc-medida-formulario
              v-if="mostrarLcMedidaFormulario"
              :processForm="processForm"
              v-model="lcMedidaForm"
              @cerrar="mostrarLcMedidaFormulario = false;"
              @modificar="modificarLcMedida"
              >
            </lc-medida-formulario>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn class="mr-2" color="error" outlined="" @click="openModal = false">
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LcMedidaFormulario from './componentes/LcMedidaFormulario.vue';
import MedidaFormulario from './componentes/MedidaFormulario.vue';

export default {
  components: {
    'medida-formulario': MedidaFormulario,
    'lc-medida-formulario': LcMedidaFormulario
  },
  data() {
    return {
      openModal: false,
      idventa: "",
      idcliente: "",
      detalles: [],
      medidaForm: {},
      lcMedidaForm: {},
      mostrarMedidaFormulario: false,
      mostrarLcMedidaFormulario: false,
      mostrarContenido: false,
      processForm: false,
    };
  },
  props: {
    objModaldetalle: {
      type: Object,
    },
  },
  watch: {
    objModaldetalle(val) {
      this.mostrarMedidaFormulario = false;
      this.mostrarLcMedidaFormulario = false;
      this.openModal = val.openModal;
      this.idventa = val.idventa;
      this.idcliente = val.idcliente;
      this.detalles = [];
      this.getdetalles();
    },
  },

  methods: {
    getdetalles() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/getDetalles",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          const detalles = response.data.detalles;
          this.detalles = detalles;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },

    datosMedidaFormulario(fila) {
      
      this.medidaForm = {
        idventa_detalle : fila.idventa_detalle,
        producto_codigo : fila.producto.codigo,
        producto_nombre : fila.producto.nombre,

        idmedida           : fila.idmedida,
        medida_nombre      : fila.medida_nombre,
        medida_fecha       : fila.medida_fecha,
        medida_oi_esfera   : fila.medida_oi_esfera,
        medida_od_esfera   : fila.medida_od_esfera,
        medida_oi_cilindro : fila.medida_oi_cilindro,
        medida_od_cilindro : fila.medida_od_cilindro,
        medida_oi_eje      : fila.medida_oi_eje ?? null,
        medida_od_eje      : fila.medida_od_eje ?? null,
        medida_dip         : fila.medida_dip ?? null,
        medida_adicion     : fila.medida_adicion > 0 ? fila.medida_adicion : fila.medida_adicion,
      }

      this.mostrarMedidaFormulario = true;
    },
    datosLcMedidaFormulario(fila) {
      
      this.lcMedidaForm = {
        idventa_detalle : fila.idventa_detalle,
        producto_codigo : fila.producto.codigo,
        producto_nombre : fila.producto.nombre,
        producto_poder     : fila.producto.poder,
        producto_cilindroo : fila.producto.cilindroo,
        producto_eje       : fila.producto.eje,
        
        lc_medida_idlc_medida  : fila.lc_medida_idlc_medida,
        lc_medida_nombre       : fila.lc_medida_nombre,
        lc_medida_fecha        : fila.lc_medida_fecha,
        lc_medida_poder_oi     : fila.lc_medida_poder_oi,
        lc_medida_poder_od     : fila.lc_medida_poder_od,
        lc_medida_astigmatismo : fila.lc_medida_astigmatismo,
        lc_medida_cilindro_oi  : fila.lc_medida_cilindro_oi,
        lc_medida_cilindro_od  : fila.lc_medida_cilindro_od,
        lc_medida_eje_oi       : fila.lc_medida_eje_oi,
        lc_medida_eje_od       : fila.lc_medida_eje_od,
      }

      this.mostrarLcMedidaFormulario = true;
    },

    

    modificarMedida() {
      
      this.modificarService({
        tipo: 'medida',
        idventa: this.idventa,
        idcliente: this.idcliente,
        idventa_detalle: this.medidaForm.idventa_detalle,
        medida: {
          idmedida           : this.medidaForm.idmedida,
          medida_nombre      : this.medidaForm.medida_nombre,
          medida_fecha       : this.medidaForm.medida_fecha,
          medida_oi_esfera   : this.medidaForm.medida_oi_esfera,
          medida_od_esfera   : this.medidaForm.medida_od_esfera,
          medida_oi_cilindro : this.medidaForm.medida_oi_cilindro,
          medida_od_cilindro : this.medidaForm.medida_od_cilindro,
          medida_oi_eje      : this.medidaForm.medida_oi_eje,
          medida_od_eje      : this.medidaForm.medida_od_eje,
          medida_dip         : this.medidaForm.medida_dip,
          medida_adicion     : this.medidaForm.medida_adicion,
        },
      })
    },
    modificarLcMedida() {

      this.modificarService({
        tipo: 'lc_medida',
        idventa: this.idventa,
        idcliente: this.idcliente,
        idventa_detalle: this.lcMedidaForm.idventa_detalle,
        lc_medida: {
          lc_medida_idlc_medida : this.lcMedidaForm.lc_medida_idlc_medida,
          lc_medida_nombre      : this.lcMedidaForm.lc_medida_nombre,
          lc_medida_fecha       : this.lcMedidaForm.lc_medida_fecha,
          lc_medida_poder_oi    : this.lcMedidaForm.lc_medida_poder_oi,
          lc_medida_poder_od    : this.lcMedidaForm.lc_medida_poder_od,
          lc_medida_astigmatismo: this.lcMedidaForm.lc_medida_astigmatismo,
          lc_medida_cilindro_oi : this.lcMedidaForm.lc_medida_cilindro_oi,
          lc_medida_cilindro_od : this.lcMedidaForm.lc_medida_cilindro_od,
          lc_medida_eje_oi      : this.lcMedidaForm.lc_medida_eje_oi,
          lc_medida_eje_od      : this.lcMedidaForm.lc_medida_eje_od,
        },
      })
        
    },
    modificarService(data) {
      this.processForm = true;
      return this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/modificarReceta",
        data: data
      })
      .then( response => {
        const data = response.data;
        this.$toasted.success(data.data, { icon: "mdi-check-bold", });
        this.$parent.listarRegistros(
          this.$parent.cmbCantidadRegistros,
          this.$parent.paginaActual,
          this.$parent.iptBuscarRegisro
        );
        
      })
      .catch( error => {
        if ( error.response === undefined) return console.error(error);
        const response = error.response;
        const data = response.data;
        
        console.error(error);
        this.$toasted.error("Ocurrio un error al modificar este registro", { icon: "mdi-close", });

      })
      .finally(() => {
        this.processForm = false;
        this.openModal = false;
      });

    },
   
  },

  created() {},
};
</script>

<style scoped>
.tabla-detalles {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-detalles thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-detalles tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-productos thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-receta thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
