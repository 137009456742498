<template>
  <div>
    <v-dialog v-model="openModal" max-width="1000" height="1000">
      <v-card class="pa-5">
        <v-card-text>
          <v-row>
            <v-col cols="12 text-center" v-show="!mostrarContenido">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="100"
                :width="7"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-show="mostrarContenido">
            <v-col cols="12">
              <p class="mb-0 font-weight-bold">Código de venta:</p>
              <p v-text="detallePago.codigoVenta"></p>
              <p class="mb-0 font-weight-bold">Código de Transacción:</p>
              <p v-text="detallePago.idtransaccion"></p>
              <p class="mb-0 font-weight-bold">Tipo de Pago:</p>
              <p v-text="detallePago.tipoPago"></p>
              <p class="mb-0 font-weight-bold">Código de pago:</p>
              <p v-text="detallePago.codigoPago"></p>
              <p class="mb-0 font-weight-bold">Cuotas:</p>
              <p v-text="detallePago.cuotas"></p>
              <p class="mb-0 font-weight-bold">Fecha de expiración:</p>
              <p v-text="detallePago.fechaExpiracion"></p>
              <p class="mb-0 font-weight-bold">Email:</p>
              <p v-text="detallePago.correo"></p>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="mr-2 text-center"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idventa: "",
      mostrarContenido: false,
      detallePago: {
        idtransaccion: "",
        codigoVenta: "",
        tipoPago: "",
        codigoPago: "",
        cuotas: 0,
        fechaExpiracion: "",
        correo: "",
      },
    };
  },
  props: {
    objModalDetallePago: {
      type: Object,
    },
  },
  watch: {
    objModalDetallePago(val) {
      this.mostrarContenido = false;
      this.openModal = val.openModal;
      this.idventa = val.idventa;
      this.getDetallePago();
    },
  },

  methods: {
    getDetallePago() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/ventas/v2/getDetallePago",
        data: {
          idventa: this.idventa,
        },
      })
        .then((response) => {
          const venta = response.data.venta;
          this.detallePago.idtransaccion = venta.idtransaccion ?? '';
          this.detallePago.codigoVenta = venta.codigo_venta;
          this.detallePago.tipoPago = venta.metodoPago.descripcion;
          this.detallePago.codigoPago =
            venta.metodoPago.descripcion == "PAGO-EFECTIVO"
              ? venta.pago_cip
              : venta.pago_ticket;
          this.detallePago.cuotas = venta.pago_cuotas;
          this.detallePago.fechaExpiracion = this.$moment(venta.pago_expira_cip).format(
            "DD/MM/YYYY hh:mm:ss A"
          );
          this.detallePago.correo = venta.pago_email;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mostrarContenido = true;
        });
    },
  },
};
</script>
